
import Vue from "vue";
import { getDate, sizeInMB, sizeInMBNumber } from "@/utils";
import { IProject, projects } from "@/api";
import { Actions } from "@/store/models";
export default Vue.extend({
  name: "GssiAdminSharedProjects",
  data() {
    return {
      index: 1,
      headers: [
        { text: "PROJECT NAME", value: "name" },
        { text: "LAST EDITED", value: "dateEdited" },
        { text: "USERNAME", value: "username" },
        { text: "PROJECT SIZE", value: "size" },
        { text: "GROUP NAME", value: "group" },
        { text: "STATUS", value: "status" },
        { text: "", value: "action" },
      ],
      searchQuery: "",
      items: [] as IProject[],
    };
  },
  computed: {
    filteredItems: function (): any[] {
      let temp = [] as any;
      this.items.forEach((p: IProject) => {
        temp.push({
          name: p.name,
          dateEdited: getDate(p.updated_datetime || p.created_datetime),
          username: p.updated_by || p.created_by,
          size: sizeInMBNumber(p.size),
          group: p.projects_associate_groups
            .map((g: any) => g.associate_group.name)
            .join(", "),
          status: p.status === 0 ? "Locked" : "Unlocked",
          id: p.id,
          company: p.company,
        });
      });
      return temp.filter((t: any) => {
        return (
          t.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          t.group.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          t.company.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    async getItems() {
      try {
        this.items = (await projects.getByCompany(undefined as any, true)).data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    view(item: IProject) {
      this.$router.push(`${item.company.id}/projects/${item.id}`);
    },
  },
});
